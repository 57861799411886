/* eslint-disable no-bitwise */
import { fabric } from 'fabric';
import type { BaseImageFilter } from 'utils/image-processing';
import { mulTable, shgTable } from './blur-contants';
import FabricFilter from './fabric-wrapper';

declare module 'fabric' {
    export interface ICustomBlurOptions {
        blur?: number;
    }

    export interface Image {
        filters: {
            CustomBlur: {
                new(options?: ICustomBlurOptions): BaseImageFilter & {
                    blur: number;
                };
            };
        };
    }
}

export interface BlurFilterOptions {
    radius: number;
}

class BlurStack {
    r = 0;
    g = 0;
    b = 0;
    a = 0;
    next: BlurStack | null = null;
}

export default class BlurCorrection extends FabricFilter {
    #blur = 1;
    static gaussianMultiplier = 2;

    processImage(src: ImageData, frameNumber: number): ImageData {
        if (frameNumber === this.currentProcessedImage) {
            return src;
        }

        const blurredImage = super.processImage(src, frameNumber);
        this.currentProcessedImage = frameNumber;
        return blurredImage;
    }

    constructor(options: BlurFilterOptions) {
        super();
        const { radius } = options;
        this.#blur = radius;

        // @ts-ignore: Custom filter
        fabric.Image.filters.CustomBlur = fabric.util.createClass(fabric.Image.filters.BaseFilter, {
            type: 'CustomBlur',
            blur: this.#blur,
            mainParameter: 'blur',
            gaussianMultiplier: BlurCorrection.gaussianMultiplier,

            initialize(ops: any) {
                this.blur = ops?.blur || 1;
            },

            applyTo2d(ops: any) {
                const blurValue = this.blur;
                const { imageData } = ops;
                const pixels = new Uint8ClampedArray(imageData.data);
                const { width, height } = imageData;

                let x: number;
                let y: number;
                let i: number;
                let p: number;
                let yp: number;
                let yi: number;
                let yw: number;
                let rSum: number;
                let gSum: number;
                let bSum: number;
                let aSum: number;
                let rOutSum: number;
                let gOutSum: number;
                let bOutSum: number;
                let aOutSum: number;
                let rInSum: number;
                let gInSum: number;
                let bInSum: number;
                let aInSum: number;
                let pr: number;
                let pg: number;
                let pb: number;
                let pa: number;
                let rbs: number;

                const originalPixels = new Uint8ClampedArray(pixels.length);
                originalPixels.set(pixels);

                const div = blurValue + blurValue + 1;
                const widthMinus1 = width - 1;
                const heightMinus1 = height - 1;
                const radiusPlus1 = blurValue + 1;
                const sumFactor = (radiusPlus1 * (radiusPlus1 + 1)) / 2;

                const stackStart = new BlurStack();
                let stack = stackStart;
                let stackEnd = null;
                let stackIn = null;
                let stackOut = null;

                for (i = 1; i < div; i++) {
                    stack.next = new BlurStack();
                    stack = stack.next;
                    if (i === radiusPlus1) stackEnd = stack;
                }
                stack.next = stackStart;

                yw = 0;
                yi = 0;
                const mulSum = mulTable[blurValue];
                const shgSum = shgTable[blurValue];

                for (y = 0; y < height; y++) {
                    rInSum = 0;
                    gInSum = 0;
                    bInSum = 0;
                    aInSum = 0;
                    rSum = 0;
                    gSum = 0;
                    bSum = 0;
                    aSum = 0;

                    pr = pixels[yi];
                    pg = pixels[yi + 1];
                    pb = pixels[yi + 2];
                    pa = pixels[yi + 3];

                    rOutSum = radiusPlus1 * pr;
                    gOutSum = radiusPlus1 * pg;
                    bOutSum = radiusPlus1 * pb;
                    aOutSum = radiusPlus1 * pa;

                    rSum += sumFactor * pr;
                    gSum += sumFactor * pg;
                    bSum += sumFactor * pb;
                    aSum += sumFactor * pa;

                    stack = stackStart;

                    for (i = 0; i < radiusPlus1; i++) {
                        stack.r = pr;
                        stack.g = pg;
                        stack.b = pb;
                        stack.a = pa;
                        stack = stack.next as BlurStack;
                    }

                    for (i = 1; i < radiusPlus1; i++) {
                        p = yi + ((widthMinus1 < i ? widthMinus1 : i) << 2);
                        pr = pixels[p];
                        stack.r = pr;
                        rbs = radiusPlus1 - i;
                        rSum += pr * rbs;
                        pg = pixels[p + 1];
                        stack.g = pg;
                        gSum += pg * rbs;
                        pb = pixels[p + 2];
                        stack.b = pb;
                        bSum += pb * rbs;
                        pa = pixels[p + 3];
                        stack.a = pa;
                        aSum += pa * rbs;

                        rInSum += pr;
                        gInSum += pg;
                        bInSum += pb;
                        aInSum += pa;

                        stack = stack.next as BlurStack;
                    }

                    stackIn = stackStart;
                    stackOut = stackEnd;

                    for (x = 0; x < width; x++) {
                        pa = (aSum * mulSum) >> shgSum;
                        pixels[yi + 3] = pa;
                        if (pa !== 0) {
                            pa = 255 / pa;
                            pixels[yi] = ((rSum * mulSum) >> shgSum) * pa;
                            pixels[yi + 1] = ((gSum * mulSum) >> shgSum) * pa;
                            pixels[yi + 2] = ((bSum * mulSum) >> shgSum) * pa;
                        } else {
                            pixels[yi] = 0;
                            pixels[yi + 1] = 0;
                            pixels[yi + 2] = 0;
                        }

                        rSum -= rOutSum;
                        gSum -= gOutSum;
                        bSum -= bOutSum;
                        aSum -= aOutSum;

                        rOutSum -= stackIn.r;
                        gOutSum -= stackIn.g;
                        bOutSum -= stackIn.b;
                        aOutSum -= stackIn.a;

                        p = x + blurValue + 1;
                        p = (yw + (p < widthMinus1 ? p : widthMinus1)) << 2;
                        stackIn.r = pixels[p];
                        stackIn.g = pixels[p + 1];
                        stackIn.b = pixels[p + 2];
                        stackIn.a = pixels[p + 3];

                        rInSum += stackIn.r;
                        gInSum += stackIn.g;
                        bInSum += stackIn.b;
                        aInSum += stackIn.a;

                        rSum += rInSum;
                        gSum += gInSum;
                        bSum += bInSum;
                        aSum += aInSum;

                        stackIn = stackIn.next as BlurStack;

                        pr = stackOut?.r;
                        pg = stackOut?.g;
                        pb = stackOut?.b;
                        pa = stackOut?.a;

                        rOutSum += pr;
                        gOutSum += pg;
                        bOutSum += pb;
                        aOutSum += pa;

                        rInSum -= pr;
                        gInSum -= pg;
                        bInSum -= pb;
                        aInSum -= pa;

                        stackOut = stackOut?.next as BlurStack;

                        yi += 4;
                    }
                    yw += width;
                }

                // Vertical pass
                for (x = 0; x < width; x++) {
                    rInSum = 0;
                    gInSum = 0;
                    bInSum = 0;
                    aInSum = 0;
                    rSum = 0;
                    gSum = 0;
                    bSum = 0;
                    aSum = 0;

                    yi = x << 2;
                    rOutSum = radiusPlus1 * (pr = pixels[yi]);
                    gOutSum = radiusPlus1 * (pg = pixels[yi + 1]);
                    bOutSum = radiusPlus1 * (pb = pixels[yi + 2]);
                    aOutSum = radiusPlus1 * (pa = pixels[yi + 3]);

                    rSum += sumFactor * pr;
                    gSum += sumFactor * pg;
                    bSum += sumFactor * pb;
                    aSum += sumFactor * pa;

                    stack = stackStart;

                    for (i = 0; i < radiusPlus1; i++) {
                        stack.r = pr;
                        stack.g = pg;
                        stack.b = pb;
                        stack.a = pa;
                        stack = stack.next as BlurStack;
                    }

                    yp = width;

                    for (i = 1; i <= blurValue; i++) {
                        yi = (yp + x) << 2;

                        pr = pixels[yi];
                        stack.r = pr;
                        rbs = radiusPlus1 - i;
                        rSum += pr * rbs;
                        pg = pixels[yi + 1];
                        stack.g = pg;
                        gSum += pg * rbs;
                        pb = pixels[yi + 2];
                        stack.b = pb;
                        bSum += pb * rbs;
                        pa = pixels[yi + 3];
                        stack.a = pa;
                        aSum += pa * rbs;

                        rInSum += pr;
                        gInSum += pg;
                        bInSum += pb;
                        aInSum += pa;

                        stack = stack.next as BlurStack;

                        if (i < heightMinus1) {
                            yp += width;
                        }
                    }

                    yi = x;
                    stackIn = stackStart;
                    stackOut = stackEnd;

                    for (y = 0; y < height; y++) {
                        p = yi << 2;
                        pa = (aSum * mulSum) >> shgSum;
                        pixels[p + 3] = pa;
                        if (pa > 0) {
                            pa = 255 / pa;
                            pixels[p] = ((rSum * mulSum) >> shgSum) * pa;
                            pixels[p + 1] = ((gSum * mulSum) >> shgSum) * pa;
                            pixels[p + 2] = ((bSum * mulSum) >> shgSum) * pa;
                        } else {
                            pixels[p] = 0;
                            pixels[p + 1] = 0;
                            pixels[p + 2] = 0;
                        }

                        rSum -= rOutSum;
                        gSum -= gOutSum;
                        bSum -= bOutSum;
                        aSum -= aOutSum;

                        rOutSum -= stackIn.r;
                        gOutSum -= stackIn.g;
                        bOutSum -= stackIn.b;
                        aOutSum -= stackIn.a;

                        p = (x + ((y + radiusPlus1) < heightMinus1 ? (y + radiusPlus1) : heightMinus1) * width) << 2;

                        stackIn.r = pixels[p];
                        stackIn.g = pixels[p + 1];
                        stackIn.b = pixels[p + 2];
                        stackIn.a = pixels[p + 3];

                        rInSum += stackIn.r;
                        gInSum += stackIn.g;
                        bInSum += stackIn.b;
                        aInSum += stackIn.a;

                        rSum += rInSum;
                        gSum += gInSum;
                        bSum += bInSum;
                        aSum += aInSum;

                        stackIn = stackIn.next as BlurStack;

                        pr = stackOut?.r ?? 0;
                        pg = stackOut?.g ?? 0;
                        pb = stackOut?.b ?? 0;
                        pa = stackOut?.a ?? 0;

                        rOutSum += pr;
                        gOutSum += pg;
                        bOutSum += pb;
                        aOutSum += pa;

                        rInSum -= pr;
                        gInSum -= pg;
                        bInSum -= pb;
                        aInSum -= pa;

                        stackOut = stackOut?.next as BlurStack;

                        yi += width;
                    }
                }

                // Apply Gaussian correction
                for (let j = 0; j < pixels.length; j++) {
                    const diff = originalPixels[j] - pixels[j];
                    pixels[j] = originalPixels[j] + diff * this.gaussianMultiplier;
                }

                imageData.data.set(pixels);
            },
        });

        // @ts-ignore: Custom filter
        this.filter = new fabric.Image.filters.CustomBlur({
            blur: this.#blur,
        });
    }

    public configure(options: object): void {
        super.configure(options);
        const { radius: newRadius } = options as BlurFilterOptions;
        console.log('Configuring blur filter with radius:', newRadius);
        this.#blur = newRadius;

        if (this.filter) {
            (this.filter as any).blur = newRadius;
            this.currentProcessedImage = null;
        }
    }

    get blur(): number {
        return this.#blur;
    }
}
