import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd/lib/grid';
import { CombinedState } from 'reducers';
import Text from 'antd/lib/typography/Text';
import Slider from 'antd/lib/slider';
import { enableImageFilter } from 'actions/settings-actions';
import { ImageFilterAlias, hasFilter } from 'utils/image-processing';
import BlurCorrection from 'utils/fabric-wrapper/blur-correction';
import debounce from 'lodash/debounce';

export default function BlurFilter(): JSX.Element {
    const dispatch = useDispatch();
    const [intensity, setIntensity] = useState<number>(0);
    const filters = useSelector((state: CombinedState) => state.settings.imageFilters);
    const blurFilter = hasFilter(filters, ImageFilterAlias.BLUR);

    useEffect(() => {
        if (!blurFilter) {
            setIntensity(0);
        } else {
            const { modifier } = blurFilter;
            const currentIntensity = (modifier as BlurCorrection).blur;
            setIntensity(currentIntensity);
        }
    }, [blurFilter, filters]);

    const debouncedFilterUpdate = useCallback(
        debounce((newIntensity: number) => {
            if (blurFilter) {
                dispatch(enableImageFilter(blurFilter, { radius: newIntensity }));
            } else {
                const filter = new BlurCorrection({ radius: newIntensity });
                dispatch(
                    enableImageFilter({
                        modifier: filter,
                        alias: ImageFilterAlias.BLUR,
                    }),
                );
            }
        }, 150),
        [blurFilter],
    );

    const onChangeIntensity = useCallback(
        (newIntensity: number): void => {
            setIntensity(newIntensity);
            debouncedFilterUpdate(newIntensity);
        },
        [debouncedFilterUpdate],
    );

    return (
        <div className='cvat-image-setups-filters'>
            <Row justify='space-around'>
                <Col span={24}>
                    <Row>
                        <Col span={6}>
                            <Text className='cvat-text-color'>Pearl Enhanced</Text>
                        </Col>
                        <Col span={12}>
                            <Slider min={1} max={20} value={intensity} step={1} onChange={onChangeIntensity} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
